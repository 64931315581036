import { initializeApp } from 'firebase/app'
import {
    ReCaptchaEnterpriseProvider,
    ReCaptchaV3Provider,
    initializeAppCheck,
} from 'firebase/app-check'
import { getAuth } from 'firebase/auth'
import {
    getFirestore,
    initializeFirestore,
    persistentLocalCache,
    persistentMultipleTabManager,
} from 'firebase/firestore'
import { getPerformance } from 'firebase/performance'
import { getStorage } from 'firebase/storage'
import firebaseConfig from '../firebaseConfig.json'

// const app = initializeApp({
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
// });

export const app = initializeApp(firebaseConfig)
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(
        process.env.REACT_APP_RECAPTCHA_KEY!
    ),
    isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
})
export const auth = getAuth(app)
export const db = initializeFirestore(app, {
    localCache: persistentLocalCache(
        /*settings*/ { tabManager: persistentMultipleTabManager() }
    ),
})
export const storage = getStorage(app)
// TODO: Additional services
// Initialize Performance Monitoring and get a reference to the service
const perf = getPerformance(app)
export default app
