import { loadStripe } from '@stripe/stripe-js'
import React, { useState } from 'react'

// NOTE: Taken from here: https://stripe.com/docs/api/customers/retrieve?lang=node

export interface TypeStripeContext {
    options: any
    stripePromise: any
    getCustomer: (customerId: string) => Promise<any>
    searchCustomersByEmail: (
        email: string,
        limit?: number,
        page?: number
    ) => Promise<any>
    getCustomerSubscriptions: (customerId: string) => Promise<any>
    getSubscription: (subscriptionId: string) => Promise<any>
}

const defaultStripeContext: TypeStripeContext = {
    options: () => ({}),
    stripePromise: () => ({}),
    getCustomer: (customerId: string) => {
        return Promise.resolve()
    },
    searchCustomersByEmail: (
        email: string,
        limit: number = 10,
        page: number | null = null
    ) => {
        return Promise.resolve()
    },
    getCustomerSubscriptions: (customerId: string) => {
        return Promise.resolve()
    },
    getSubscription: (subscriptionId: string) => {
        return Promise.resolve()
    },
}

const StripeContext =
    React.createContext<TypeStripeContext>(defaultStripeContext)

function useStripeContext() {
    const context = React.useContext(StripeContext)
    return context
}

function StripeProvider({ children }: any) {
    const [loading, setLoading] = useState(true)

    const [clientSecret, setClientSecret] = useState('')

    // useEffect(() => {
    //     // Create PaymentIntent as soon as the page loads
    //     fetch('/create-payment-intent', {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({ items: [{ id: 'xl-tshirt' }] }),
    //     })
    //         .then((res) => res.json())
    //         .then((data) => setClientSecret(data.clientSecret))
    // }, [])

    const appearance = {
        theme: 'stripe',
    }

    const options = {
        appearance,
    }

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!)

    function getCustomer(customerId: string) {
        return fetch(`https://api.stripe.com/v1/customers/${customerId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
                'Content-Type': 'application/json',
            },
        })
            .then((res) => res.json())
            .then((data) => {
                return data
            })
            .catch((error) => {
                console.error(error)
                return ''
            })
    }

    function searchCustomersByEmail(
        email: string,
        limit: number = 10,
        page: number | null = null
    ) {
        return fetch(
            `https://api.stripe.com/v1/customers/search?query=email:'${email}'&limit=${limit}${
                page ? '&page=' + page : ''
            }`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                return data
            })
            .catch((error) => {
                console.error(error)
                return ''
            })
    }

    function getCustomerSubscriptions(customerId: string) {
        return fetch(`https://api.stripe.com/v1/customers/${customerId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                expand: [
                    'data.subscriptions',
                    'data.subscriptions.default_payment_method',
                ],
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                return data
            })
            .catch((error) => {
                console.error(error)
                return ''
            })
    }

    function getSubscription(subscriptionId: string) {
        return fetch(
            `https://api.stripe.com/v1/subscriptions/${subscriptionId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                return data
            })
            .catch((error) => {
                console.error(error)
                return ''
            })
    }

    const value = {
        options,
        stripePromise,
        getCustomer,
        searchCustomersByEmail,
        getCustomerSubscriptions,
        getSubscription,
    }

    return (
        <StripeContext.Provider value={value}>
            {children}
        </StripeContext.Provider>
    )
}

export { StripeProvider, useStripeContext }
