/* eslint-disable @typescript-eslint/indent */
/* eslint-disable indent */

import {
    DocumentData,
    DocumentReference,
    DocumentSnapshot,
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    setDoc,
    startAfter,
    where,
} from 'firebase/firestore'
import React, { useState } from 'react'
import { db, storage } from '../utils/Firebase'
import { BrokerObject } from '../models/BrokerObject'
import {
    UserInformationObject,
    createEmptyUserInformationObject,
    createUserInformationObject,
} from '../models/UserInformationObject'
import { userInfo } from 'os'
import {
    UserPreferencesObject,
    createEmptyUserPreferencesObject,
} from '../models/UserPreferencesObject'
import {
    StorageError,
    UploadTask,
    getDownloadURL,
    ref,
    uploadBytesResumable,
} from 'firebase/storage'
import { NotificationObject } from '../models/NotificationObject'
import { TradingBotObject } from '../models/TradingBotObject'

export interface ISearchFilters {
    categories: string[]
    tags: string[]
    subcategories: string[]
    locations: string[]
    prices: string[]
    ratings: string[]
    sort: string
    searchTerm: string
}

export interface IMarketPlaceFilters {
    categories: string[]
    tags: string[]
    subcategories: string[]
    locations: string[]
    prices: string[]
    ratings: string[]
    sort: string
    searchTerm: string
}

export enum NotificationType {
    Email = 'Email',
    SMS = 'SMS',
    Discord = 'Discord',
    Telegram = 'Telegram',
}

// https://github.com/WebDevSimplified/React-Firebase-Auth/blob/master/src/contexts/AuthContext.js

export interface TypeDbContext {
    getSearchItem: (id: string) => Promise<any>
    getSearchItems: (
        filters: ISearchFilters | undefined | null,
        pageLimit?: number,
        page?: number
    ) => Promise<any>
    getMarketplaceItem: (id: string) => Promise<any>
    getMarketplaceItems: (
        filters: IMarketPlaceFilters | undefined | null
    ) => Promise<any>
    getUserPreferences: (uid: string) => Promise<any>
    setUserPreferences: (
        uid: string,
        value: UserPreferencesObject
    ) => Promise<any>
    getUserInformation: (uid: string) => Promise<any>
    setUserInformation: (
        uid: string,
        value: UserInformationObject | undefined
    ) => Promise<any>
    getNotifications: (uid: string) => Promise<NotificationObject[]>
    setNotifications: (
        uid: string,
        notifications: NotificationObject[]
    ) => Promise<any>
    getNotificationSettings: (uid: string) => Promise<any>
    setNotificationSettings: (
        uid: string,
        notifications: string
    ) => Promise<any>
    getBrokers: (uid: string) => Promise<any>
    setBrokers: (uid: string, value: BrokerObject[]) => Promise<any>
    getBrokerSettings: (uid: string) => Promise<any>
    setBrokerSettings: (uid: string, value: string) => Promise<any>
    getTradingBots: (uid: string) => Promise<any>
    setTradingBots: (
        uid: string,
        tradingBots: TradingBotObject[]
    ) => Promise<any>
    getSubscription: (uid: string) => Promise<string>
    setSubscription: (uid: string, value: string) => Promise<any>
    getPlanType: (uid: string) => Promise<string>
    getFile: (uid: string, filepath: string) => Promise<any>
    setFile: (
        uid: string,
        filename: string,
        file: any,
        metadata: any,
        successCallback: Function,
        errorCallback: Function
    ) => Promise<any>
    getProfileImage(uid: string): Promise<string | undefined>
    setProfileImage(
        uid: string,
        file: any,
        metadata: any,
        successCallback: Function,
        errorCallback: Function
    ): Promise<any>
    getStrategies(): Promise<any>
    getJobLogs(uid: string, datetime: string, jobid: string): Promise<any>
    getNotificationLogs(uid: string, datetime: string): Promise<any>
    getPosition(uid: string, symbol: string, datetime: string): Promise<any>
}

const defaultDbContext: TypeDbContext = {
    getSearchItem: async (id: string) => {
        await Promise.resolve()
    },
    getSearchItems: async (
        filters: ISearchFilters | undefined | null,
        pageLimit: number = 10,
        page: number = -1
    ) => {
        await Promise.resolve()
    },
    getMarketplaceItem: async (id: string) => {
        await Promise.resolve()
    },
    getMarketplaceItems: async (
        filters: IMarketPlaceFilters | undefined | null
    ) => {
        await Promise.resolve()
    },
    getUserPreferences: async (uid: string) => {
        await Promise.resolve()
    },
    setUserPreferences: async (uid: string, value: UserPreferencesObject) => {
        await Promise.resolve()
    },
    getUserInformation: async (uid: string) => {
        await Promise.resolve()
    },
    setUserInformation: async (
        uid: string,
        value: UserInformationObject | undefined
    ) => {
        await Promise.resolve()
    },
    getNotifications: async (uid: string): Promise<NotificationObject[]> => {
        return await Promise.resolve([])
    },
    setNotifications: async (
        uid: string,
        notifications: NotificationObject[]
    ) => {
        await Promise.resolve()
    },
    getNotificationSettings: async (uid: string) => {
        await Promise.resolve()
    },
    setNotificationSettings: async (uid: string, value: string) => {
        await Promise.resolve()
    },
    getBrokers: async (uid: string) => {
        await Promise.resolve()
    },
    setBrokers: async (uid: string, value: BrokerObject[]) => {
        await Promise.resolve()
    },
    getBrokerSettings: async (uid: string) => {
        await Promise.resolve()
    },
    setBrokerSettings: async (uid: string, value: string) => {
        await Promise.resolve()
    },
    getTradingBots: async (uid: string) => {
        await Promise.resolve()
    },
    setTradingBots: async (uid: string, tradingBots: TradingBotObject[]) => {
        await Promise.resolve()
    },
    getSubscription(uid: string) {
        return Promise.resolve('')
    },
    setSubscription(uid: string, value: string) {
        return Promise.resolve()
    },
    getPlanType(uid: string) {
        return Promise.resolve('')
    },
    getFile(uid: string, filepath: string) {
        return Promise.resolve('')
    },
    setFile(
        uid: string,
        filename: string,
        file: any,
        metadata: any,
        successCallback: Function,
        errorCallback: Function
    ) {
        return Promise.resolve()
    },
    getProfileImage(uid: string) {
        return Promise.resolve(undefined)
    },
    setProfileImage(
        uid: string,
        file: any,
        metadata: any,
        successCallback: Function,
        errorCallback: Function
    ) {
        return Promise.resolve()
    },
    getStrategies() {
        return Promise.resolve([])
    },
    getJobLogs(uid: string, datetime: string, jobid: string) {
        return Promise.resolve([])
    },
    getNotificationLogs(uid: string, datetime: string) {
        return Promise.resolve()
    },
    getPosition(uid: string, symbol: string, datetime: string) {
        return Promise.resolve()
    },
}

const DbContext = React.createContext<TypeDbContext>(defaultDbContext)

function useDbContext() {
    const context = React.useContext(DbContext)
    return context
}

function DbProvider({ children }: any) {
    async function getSearchItem(id: string) {
        const docRef = doc(db, 'user-strategy-search', id)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            Object.assign({ id: docSnap.id }, docSnap.data())
            return docSnap.data()
        } else {
            return null
        }
    }

    async function getSearchItems(
        filters: ISearchFilters | undefined | null,
        pageLimit: number = 10,
        page: number = -1
    ) {
        const items: any[] = []
        const collections = [
            'data-available-symbols',
            'user-strategy-marketplace',
        ]
        for (const c in collections) {
            try {
                const searchRef = collection(db, c)
                let q = null
                if (!filters) {
                    if (page === -1) {
                        q = query(
                            searchRef,
                            orderBy('name', 'desc'),
                            limit(pageLimit)
                        )
                    } else {
                        q = query(
                            searchRef,
                            orderBy('name', 'desc'),
                            limit(pageLimit),
                            startAfter(page)
                        )
                    }
                } else {
                    if (page === -1) {
                        q = query(
                            searchRef,
                            orderBy('name', 'desc'),
                            limit(pageLimit),
                            where('category', 'in', filters.categories),
                            where('tag', 'in', filters.tags),
                            where('subcategory', 'in', filters.subcategories),
                            where('location', 'in', filters.locations),
                            where('price', 'in', filters.prices),
                            where('rating', 'in', filters.ratings),
                            where('name', '>=', filters.searchTerm)
                        )
                    } else {
                        q = query(
                            searchRef,
                            orderBy('name', 'desc'),
                            limit(pageLimit),
                            startAfter(page),
                            where('category', 'in', filters.categories),
                            where('tag', 'in', filters.tags),
                            where('subcategory', 'in', filters.subcategories),
                            where('location', 'in', filters.locations),
                            where('price', 'in', filters.prices),
                            where('rating', 'in', filters.ratings),
                            where('name', '>=', filters.searchTerm)
                        )
                    }
                }
                const querySnapshot = await getDocs(q)
                querySnapshot.forEach((doc) => {
                    items.push(Object.assign({ id: doc.id }, doc.data()))
                })
            } catch (e) {
                console.log(e)
            }
        }

        return items
    }

    async function getMarketplaceItem(id: string) {
        const docRef = doc(db, 'user-strategy-marketplace', id)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            Object.assign({ id: docSnap.id }, docSnap.data())
            return docSnap.data()
        } else {
            return null
        }
    }

    async function getMarketplaceItems(
        filters: IMarketPlaceFilters | undefined | null,
        pageLimit: number = 10,
        page: number = -1
    ) {
        const marketplaceRef = collection(db, 'user-strategy-marketplace')
        let q = null
        if (!filters) {
            if (page === -1) {
                q = query(
                    marketplaceRef,
                    orderBy('name', 'desc'),
                    limit(pageLimit)
                )
            } else {
                q = query(
                    marketplaceRef,
                    orderBy('name', 'desc'),
                    limit(pageLimit),
                    startAfter(page)
                )
            }
        } else {
            if (page === -1) {
                q = query(
                    marketplaceRef,
                    where('rating', '>=', 4),
                    orderBy('name', 'desc'),
                    limit(pageLimit)
                )
            } else {
                q = query(
                    marketplaceRef,
                    where('rating', '>=', 4),
                    orderBy('name', 'desc'),
                    limit(pageLimit),
                    startAfter(page)
                )
            }
        }
        const querySnapshot = await getDocs(q)
        // const ref = doc(db, `user-marketplace-items`)
        const docsData = querySnapshot.docs.map((doc) =>
            Object.assign({ id: doc.id }, doc.data())
        )
        return {
            data: docsData,
            lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
        }
    }

    async function getUserPreferences(uid: string) {
        // const q = query(collection(db, 'user-preferences'), where('Document ID', '==', uid));
        // return (await getDocs(q));
        // return (await getDocs(collection(db, `user-preferences/${uid}`))).docs.filter(
        //     (doc) => doc.id === uid
        // )
        // return await getDoc(doc(db, `user-preferences/${uid}`))
        const ref = doc(db, `user-preferences/${uid}`)
        const response = (await getDoc(ref)).data() as UserPreferencesObject
        if (response === undefined) {
            return createEmptyUserPreferencesObject()
        }
        return response
    }

    async function setUserPreferences(
        uid: string,
        value: UserPreferencesObject | undefined
    ) {
        if (value == null) return
        const ref = doc(db, `user-preferences/${uid}`)
        await setDoc(ref, value)
    }

    async function getUserInformation(uid: string) {
        const ref = doc(db, `user-information/${uid}`)
        const response = (await getDoc(ref)).data() as UserInformationObject
        if (response === undefined) {
            return createEmptyUserInformationObject()
        }
        return response
    }

    async function setUserInformation(
        uid: string,
        value: UserInformationObject | undefined
    ) {
        if (value == null) return
        const ref = doc(db, `user-information/${uid}`)
        await setDoc(ref, value)
    }

    async function getNotifications(
        uid: string
    ): Promise<NotificationObject[]> {
        if (uid == null) return []
        let notifications: NotificationObject[] = []
        return getUserPreferences(uid)
            .then((userPreferences: any) => {
                notifications = JSON.parse(userPreferences.notifications)
                return notifications
            })
            .catch((error: any) => {
                return error
            })
    }

    async function setNotifications(
        uid: string,
        notifications: NotificationObject[]
    ) {
        if (uid == null) return
        return getUserPreferences(uid)
            .then((userPreferences: any) => {
                userPreferences.notifications = JSON.stringify(notifications)
                return setUserPreferences(uid, userPreferences)
                    .then(() => {
                        return value
                    })
                    .catch((error: any) => {
                        return error
                    })
            })
            .then((notifications: any) => {
                return notifications
            })
            .catch((error: any) => {
                return error
            })
    }

    async function setNotificationSettings(uid: string, value: string) {
        const ref = doc(db, `user-preferences/${uid}`)
        let data = (await getDoc(ref)).data()
        if (data == undefined) data = {}
        data.notifications = value
        await setDoc(ref, data)
    }

    async function getNotificationSettings(uid: string) {
        const ref: any = doc(db, `user-preferences/${uid}`)
        const docs: any = (await getDoc(ref)).data()
        if (docs != undefined && docs.notifications != undefined) {
            return await docs.notifications
        } else {
            return await ''
        }
    }

    async function getBrokers(uid: string): Promise<BrokerObject[]> {
        if (uid == null) return []
        let brokers: BrokerObject[] = []
        return getUserPreferences(uid)
            .then((userPreferences: UserPreferencesObject) => {
                if (userPreferences.brokers == undefined) {
                    return []
                }
                brokers = JSON.parse(userPreferences.brokers)
                return brokers
            })
            .catch((error: any) => {
                return error
            })
    }

    async function setBrokers(uid: string, value: BrokerObject[]) {
        if (uid == null) return
        return getUserPreferences(uid)
            .then((userPreferences: UserPreferencesObject) => {
                userPreferences.brokers = JSON.stringify(value)
                return setUserPreferences(uid, userPreferences)
                    .then(() => {
                        return userPreferences.brokers
                    })
                    .catch((error: any) => {
                        return error
                    })
            })
            .then(() => {
                return value
            })
            .catch((error: any) => {
                return error
            })
    }

    async function getBrokerSettings(uid: string) {
        const ref: any = doc(db, `user-preferences/${uid}`)
        const docs: any = (await getDoc(ref)).data()
        if (docs != undefined && docs.brokers != undefined) {
            return await docs.brokers
        } else {
            return await ''
        }
    }

    async function setBrokerSettings(uid: string, value: string) {
        const ref = doc(db, `user-preferences/${uid}`)
        let data = (await getDoc(ref)).data()
        if (data == undefined) data = {}
        data.brokers = value
        await setDoc(ref, data)
    }

    async function getTradingBots(uid: string): Promise<TradingBotObject[]> {
        if (uid == null) return []
        let tradingBots: TradingBotObject[] = []
        return getUserPreferences(uid)
            .then((userPreferences: UserPreferencesObject) => {
                if (userPreferences.tradingBots == undefined) {
                    return []
                }
                tradingBots = JSON.parse(userPreferences.tradingBots)
                return tradingBots
            })
            .catch((error: any) => {
                return error
            })
    }

    async function setTradingBots(uid: string, value: TradingBotObject[]) {
        if (uid == null) return
        return getUserPreferences(uid)
            .then((userPreferences: UserPreferencesObject) => {
                userPreferences.tradingBots = JSON.stringify(value)
                return setUserPreferences(uid, userPreferences)
                    .then(() => {
                        return userPreferences.tradingBots
                    })
                    .catch((error: any) => {
                        return error
                    })
            })
            .then(() => {
                return value
            })
            .catch((error: any) => {
                return error
            })
    }

    async function getSubscription(uid: string) {
        const ref: any = doc(db, `user-preferences/${uid}`)
        const docs: any = (await getDoc(ref)).data()
        if (docs != undefined && docs.subscription != undefined) {
            return await docs.stripeSubscriptionId
        } else {
            return await ''
        }
    }

    async function setSubscription(uid: string, value: string) {
        const ref = doc(db, `user-preferences/${uid}`)
        let data = (await getDoc(ref)).data()
        if (data == undefined) data = {}
        data.stripeSubscriptionId = value
        await setDoc(ref, data)
    }

    async function getPlanType(uid: string) {
        const stripeSubscriptionId = await getSubscription(uid)
        if (stripeSubscriptionId == '') {
            return await 'Trial'
        }
        let productId: string = ''
        const getProductId = await (async () => {
            fetch(
                `https://api.stripe.com/v1/subscriptions/${stripeSubscriptionId}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    productId = data.items.data[0].plan.product
                })
                .catch((error) => {
                    console.error(error)
                    productId = 'TRIAL'
                })
        })
        if (productId == 'TRIAL') {
            return await 'TRIAL'
        }
        let planType: string = ''
        const getPlanType = await (async () => {
            fetch(`https://api.stripe.com/v1/products/${productId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    planType = data.name.split(' ')[0].toUpperCase()
                })
                .catch((error) => {
                    console.error(error)
                    planType = 'TRIAL'
                })
        })
        return planType
    }

    async function getFile(
        uid: string,
        filename: string
    ): Promise<string | undefined> {
        const storageRef = ref(storage, `${uid}/${filename}`)
        return getDownloadURL(storageRef)
            .then((url) => {
                return url
            })
            .catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/object-not-found':
                    // File doesn't exist
                    case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    case 'storage/canceled':
                    // User canceled the upload
                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        return undefined
                }
            })
    }

    async function setFile(
        uid: string,
        filename: string,
        file: any,
        metadata: any,
        successCallback: Function,
        errorCallback: Function
    ): Promise<any> {
        const storageRef = ref(storage, `${uid}/${filename}`)

        const uploadTask = uploadBytesResumable(storageRef, file, metadata)

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                switch (snapshot.state) {
                    case 'paused':
                        break
                    case 'running':
                        break
                }
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    case 'storage/canceled':
                    // User canceled the upload
                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        errorCallback()
                }
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then(
                    (downloadURL: any) => {
                        successCallback(downloadURL)
                    }
                )
            }
        )
    }

    async function getProfileImage(uid: string): Promise<string | undefined> {
        return getFile(uid, 'profile.png').then((url) => {
            if (url != undefined) {
                return url
            }
            return undefined
        })
    }

    async function setProfileImage(
        uid: string,
        file: any,
        metadata: any,
        successCallback: Function,
        errorCallback: Function
    ) {
        return setFile(
            uid,
            'profile.png',
            file,
            metadata,
            successCallback,
            errorCallback
        )
    }

    async function getStrategies() {
        const ref: any = doc(db, `strategies/default`)
        const docs: any = (await getDoc(ref)).data()
        if (docs != undefined && docs.strategies != undefined) {
            return await docs.strategies.split(',')
        } else {
            console.warn('No strategies found')
            return await []
        }
    }

    async function getJobLogs(uid: string, datetime: string, jobid: string) {
        if (!uid) {
            return await []
        }
        const ref: any = doc(db, `user-jobs/${uid}/${datetime}/${jobid}`)
        const docData: any = (await getDoc(ref)).data()
        if (docData != undefined) {
            return await docData
        } else {
            return await []
        }
    }

    async function getNotificationLogs(uid: string, datetime: string) {
        if (!uid) {
            return await []
        }
        const ref: any = doc(
            db,
            `user-notified/${uid}/notification/${datetime}`
        )
        const docData: any = (await getDoc(ref)).data()
        if (docData != undefined) {
            return await docData
        } else {
            return await ''
        }
    }

    async function getPosition(uid: string, symbol: string, datetime: string) {
        if (!uid || !symbol || !datetime) {
            return await ''
        }
        const ref = doc(db, `user-position/${uid}/${symbol}/${datetime}`)
        const docData = (await getDoc(ref)).data()
        return docData
    }

    const value = {
        getSearchItem,
        getSearchItems,
        getMarketplaceItem,
        getMarketplaceItems,
        getUserPreferences,
        setUserPreferences,
        getUserInformation,
        setUserInformation,
        getNotifications,
        setNotifications,
        getNotificationSettings,
        setNotificationSettings,
        getBrokers,
        setBrokers,
        getBrokerSettings,
        setBrokerSettings,
        getTradingBots,
        setTradingBots,
        getSubscription,
        setSubscription,
        getPlanType,
        getFile,
        setFile,
        getProfileImage,
        setProfileImage,
        getStrategies,
        getJobLogs,
        getNotificationLogs,
        getPosition,
    }

    return <DbContext.Provider value={value}>{children}</DbContext.Provider>
}

export { DbProvider, useDbContext }
