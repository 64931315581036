import React, { useState } from 'react'
import { auth } from '../utils/Firebase'
import {
    GoogleAuthProvider,
    type User,
    confirmPasswordReset,
    createUserWithEmailAndPassword,
    inMemoryPersistence,
    sendPasswordResetEmail,
    setPersistence,
    signInWithEmailAndPassword,
    signInWithRedirect,
    updateEmail,
    updatePassword,
    updateProfile,
} from 'firebase/auth'

// https://github.com/WebDevSimplified/React-Firebase-Auth/blob/master/src/contexts/AuthContext.js

export interface TypeAuthContext {
    currentUser: User | undefined
    authenticated: () => boolean
    login: (email: string, password: string) => Promise<any>
    signup: (email: string, password: string) => Promise<any>
    logout: () => Promise<any>
    localResetPassword: (email: string) => Promise<any>
    localUpdateEmail: (email: string) => Promise<any>
    localUpdatePassword: (password: string) => Promise<any>
    localUpdateProfile: (
        user: User,
        displayName?: string,
        photoURL?: string
    ) => Promise<any>
    localConfirmPasswordReset: (
        code: string,
        newPassword: string
    ) => Promise<any>
    loginNonPersistant: (email: string, password: string) => Promise<any>
}

const defaultAuthContext: TypeAuthContext = {
    currentUser: undefined,
    authenticated: () => false,
    login: async (email: string, password: string) => {
        await Promise.resolve()
    },
    signup: async (email: string, password: string) => {
        await Promise.resolve()
    },
    logout: async () => {
        await Promise.resolve()
    },
    localResetPassword: async (email: string) => {
        await Promise.resolve()
    },
    localUpdateEmail: async (email: string) => {
        await Promise.resolve()
    },
    localUpdatePassword: async (password: string) => {
        await Promise.resolve()
    },
    localUpdateProfile: async (
        user: User,
        displayName?: string,
        photoURL?: string
    ) => {
        await Promise.resolve()
    },
    localConfirmPasswordReset: async (code: string, newPassword: string) => {
        await Promise.resolve()
    },
    loginNonPersistant: async (email: string, password: string) => {
        await Promise.resolve()
    },
}

defaultAuthContext.currentUser = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user') || '')
    : undefined
if (defaultAuthContext.currentUser == null && auth.currentUser != null) {
    defaultAuthContext.currentUser = auth.currentUser
    localStorage.setItem('user', JSON.stringify(auth.currentUser))
}

const AuthContext = React.createContext<TypeAuthContext>(defaultAuthContext)

function useAuthContext() {
    const context = React.useContext(AuthContext)
    return context
}

function AuthProvider({ children }: any) {
    const [currentUser, setCurrentUser] = useState<User | undefined>()
    const [loading, setLoading] = useState(true)

    auth.onAuthStateChanged((user) => {
        if (
            (user == null && currentUser == null) ||
            (user != null &&
                currentUser != null &&
                user.uid === currentUser.uid)
        ) {
            return
        }
        if (user == null) {
            localStorage.removeItem('user')
            setCurrentUser(undefined)
            setLoading(false)
        } else {
            localStorage.setItem('user', JSON.stringify(user))
            setCurrentUser(user)
            setLoading(false)
        }
    })

    // useEffect(() => {
    //     const unsubscribe = auth.onAuthStateChanged((user) => {
    //         if (!user) {
    //             localStorage.removeItem('user');
    //             setCurrentUser(undefined)
    //             setLoading(false)
    //             return <Navigate to='/login' />;
    //         }
    //         localStorage.setItem('user', JSON.stringify(user));
    //         setCurrentUser(user);
    //         return;
    //     })

    //     return unsubscribe
    // }, [])

    function authenticated() {
        return auth.currentUser != null
    }

    async function signup(email: string, password: string) {
        return await createUserWithEmailAndPassword(auth, email, password)
    }

    async function login(email: string, password: string) {
        return await signInWithEmailAndPassword(auth, email, password)
    }

    async function logout() {
        await auth.signOut()
    }

    async function loginNonPersistant(email: string, password: string) {
        await setPersistence(auth, inMemoryPersistence)
            .then(async () => {
                const provider = new GoogleAuthProvider()
                return await signInWithRedirect(auth, provider)
            })
            .catch((error) => {
                const errorCode = error.code
                const errorMessage = error.message
                console.log(errorCode, errorMessage)
            })
    }

    async function localUpdateProfile(
        user: User,
        displayName?: string,
        photoURL?: string
    ) {
        if (!user) {
            return await Promise.reject(new Error('No current user'))
        }
        if (!displayName) {
            return await Promise.reject(new Error('No displayName'))
        }
        await updateProfile(user, { displayName, photoURL })
    }

    async function localResetPassword(email: string) {
        await sendPasswordResetEmail(auth, email)
    }

    async function localConfirmPasswordReset(
        code: string,
        newPassword: string
    ) {
        await confirmPasswordReset(auth, code, newPassword)
    }

    async function localUpdateEmail(email: string) {
        if (currentUser == null) {
            return await Promise.reject(new Error('No current user'))
        }
        await updateEmail(currentUser, email)
    }

    async function localUpdatePassword(password: string) {
        if (currentUser == null) {
            return await Promise.reject(new Error('No current user'))
        }
        await updatePassword(currentUser, password)
    }

    const value = {
        currentUser,
        authenticated,
        login,
        signup,
        logout,
        localResetPassword,
        localUpdateProfile,
        localUpdateEmail,
        localUpdatePassword,
        localConfirmPasswordReset,
        loginNonPersistant,
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export { AuthProvider, useAuthContext }
