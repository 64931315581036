import 'animate.css'
import React, { Suspense } from 'react'
import './App.css'
import * as Sentry from '@sentry/react'

import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import LoadingModal from './components/loadingmodal/LoadingModal'
import router from './routes/Routes'
import { Sheet, Typography } from '@mui/joy'
import TagManager from 'react-gtm-module'

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [
        new Sentry.BrowserProfilingIntegration(),
        new Sentry.Replay(),
        new Sentry.BrowserTracing(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    profilesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        'localhost',
        'https://localhost.obsidianrhythm.app',
        'https://obsidianrhythm.app/api/',
        'https://api.obsidianrhythm.app/',
        'https://obsidianrhythm.app',
        'https://staging.obsidianrhythm.app',
        'https://development.obsidianrhythm.app',
    ],
})

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID!,
}

TagManager.initialize(tagManagerArgs)

export default function App() {
    const [loading, setLoading] = React.useState<boolean>(true)

    React.useEffect(() => {
        setLoading(false)
    }, [])

    return (
        <Suspense fallback={<LoadingModal loading={loading} />}>
            <RouterProvider router={router} />
        </Suspense>
    )
}
