/* eslint-disable @typescript-eslint/naming-convention */
import { BrokerObject } from './BrokerObject'
import { NotificationObject } from './NotificationObject'
export interface UserPreferencesObject {
    brokers: string
    notifications: string
    tradingBots: string
    userActive: boolean
    userCreatedDate: string
    userDeleteDate: string
    userDeleted: boolean
    stripeCustomerId: string
    stripeSubscriptionId: string
}

export const createEmptyUserPreferencesObject = () => {
    return {
        brokers: '',
        notifications: '',
        tradingBots: '',
        userActive: false,
        userCreatedDate: '',
        userDeleteDate: '',
        userDeleted: false,
        stripeCustomerId: '',
        stripeSubscriptionId: '',
    } as UserPreferencesObject
}

export const createUserPreferencesObject = (data: any) => {
    return {
        brokers: data.get('brokers')!.toString(),
        notifications: data.get('notifications')!.toString(),
        tradingBots: data.get('tradingBots')!.toString(),
        userActive: data.get('userActive')! === 'on' ? true : false,
        userCreatedDate: data.get('userCreatedDate')!.toString(),
        userDeleteDate: data.get('userDeleteDate')!.toString(),
        userDeleted: data.get('userDeleted')! === 'on' ? true : false,
        stripeCustomerId: '',
        stripeSubscriptionId: '',
    } as UserPreferencesObject
}
