import 'animate.css'
import React, { Suspense } from 'react'
import '../App.css'
import * as Sentry from '@sentry/react'

import { RouterProvider, createBrowserRouter } from 'react-router-dom'

const Root = React.lazy(async () => await import('../Root'))
const Home = React.lazy(async () => await import('../pages/Home/Home'))
const Dashboard = React.lazy(
    async () => await import('../pages/Dashboard/Dashboard')
)
const Login = React.lazy(async () => await import('../pages/Login/Login'))
const Logout = React.lazy(async () => await import('../pages/Logout/Logout'))
const ForgotPassword = React.lazy(
    async () => await import('../pages/ForgotPassword/ForgotPassword')
)
const ForgotPasswordConfirm = React.lazy(
    async () => await import('../pages/ForgotPassword/ForgotPasswordConfirm')
)
const Register = React.lazy(
    async () => await import('../pages/Register/Register')
)
const Search = React.lazy(async () => await import('../pages/Search/Search'))
const Marketplace = React.lazy(
    async () => await import('../pages/marketplace/Marketplace')
)
const Strategy = React.lazy(
    async () => await import('../pages/Strategy/Strategy')
)
const Leaderboard = React.lazy(
    async () => await import('../pages/Leaderboard/Leaderboard')
)
const Support = React.lazy(async () => await import('../pages/Support/Support'))
const Settings = React.lazy(
    async () => await import('../pages/Settings/Settings')
)
const Documentation = React.lazy(
    async () => await import('../pages/Documentation/Documentation')
)
const About = React.lazy(async () => await import('../pages/About/About'))
const Reports = React.lazy(async () => await import('../pages/Reports/Reports'))
const Terms = React.lazy(async () => await import('../pages/Terms/Terms'))
const Privacy = React.lazy(async () => await import('../pages/Privacy/Privacy'))
const NotFound = React.lazy(
    async () => await import('../components/notfound/NotFound')
)

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        children: [
            {
                path: 'home',
                element: <Home />,
            },
            {
                path: 'about',
                element: <About />,
            },
            {
                path: 'login',
                element: <Login />,
            },
            {
                path: 'logout',
                element: <Logout />,
            },
            {
                path: 'register',
                element: <Register />,
            },
            {
                path: 'forgot-password',
                element: <ForgotPassword />,
            },
            {
                path: 'forgot-password/confirm',
                element: <ForgotPasswordConfirm />,
            },
            {
                path: 'dashboard',
                element: <Dashboard />,
            },
            {
                path: 'search',
                element: <Search />,
            },
            // {
            //     path: 'marketplace',
            //     element: <Marketplace />,
            // },
            // {
            //     path: 'strategy/:id',
            //     element: <Strategy />,
            // },
            // {
            //     path: 'leaderboard',
            //     element: <Leaderboard />,
            // },
            {
                path: 'reports',
                element: <Reports />,
            },
            {
                path: 'documentation',
                element: <Documentation />,
            },
            {
                path: 'support',
                element: <Support />,
            },
            {
                path: 'settings',
                element: <Settings />,
            },
            {
                path: 'terms',
                element: <Terms />,
            },
            {
                path: 'privacy',
                element: <Privacy />,
            },
        ],
        errorElement: <NotFound />,
    },
    // {
    //     path: '*',
    //     element: <Navigate to="/" />,
    // },
])
export default router
