/* eslint-disable react/react-in-jsx-scope */
import Modal from '@mui/joy/Modal/Modal'
import ModalDialog from '@mui/joy/ModalDialog/ModalDialog'
import Typography from '@mui/joy/Typography/Typography'
import { CircularProgress } from '@mui/material'

export default function LoadingModal({ loading }: { loading: boolean }) {
    return (
        <Modal open={!!loading}>
            <ModalDialog
                sx={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {/* <ModalClose /> */}
                <CircularProgress sx={{ flexGrow: 1 }} />
                <Typography level="body-lg">Loading</Typography>
            </ModalDialog>
        </Modal>
    )
}
