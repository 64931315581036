export interface UserInformationObject {
    givenname: string
    surname: string
    role: string
    referral: string
    billingAddress: string
    mailingAddress: string
    timeZone: string
    countryCode: string
}

export const createEmptyUserInformationObject = () => {
    return {
        givenname: '',
        surname: '',
        role: '',
        referral: '',
        billingAddress: '',
        mailingAddress: '',
        timeZone: '',
        countryCode: '',
    } as UserInformationObject
}

export const createUserInformationObject = (data: any) => {
    return {
        givenname: data.get('givenname')!.toString(),
        surname: data.get('surname')!.toString(),
        role: data.get('role')!.toString(),
        referral: data.get('referral')?.toString() ?? '',
        billingAddress: data.get('billingAddress')?.toString() ?? '',
        mailingAddress: data.get('mailingAddress')?.toString() ?? '',
        timeZone: data.get('timeZone')!.toString(),
    } as UserInformationObject
}
