import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import App from './App'
import { AuthProvider } from './context/AuthContext'
import GlobalStyles from '@mui/joy/GlobalStyles'
import { CssVarsProvider, ThemeProvider } from '@mui/joy/styles'
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import {
    THEME_ID as MATERIAL_THEME_ID,
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    experimental_extendTheme as materialExtendTheme,
} from '@mui/material/styles'
import customTheme from './assets/theme/Theme'
import { DbProvider } from './context/DbContext'
import { StripeProvider } from './context/StripeContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <AuthProvider>
            <DbProvider>
                <StripeProvider>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={customTheme}>
                            <MaterialCssVarsProvider
                                theme={{
                                    [MATERIAL_THEME_ID]: materialExtendTheme(),
                                }}
                            >
                                <CssVarsProvider
                                    defaultMode="dark"
                                    disableTransitionOnChange
                                    theme={customTheme}
                                >
                                    <CssBaseline />
                                    <GlobalStyles
                                        styles={(theme) => ({
                                            ':root': {
                                                '--Collapsed-breakpoint':
                                                    '769px', // form will stretch when viewport is below `769px`
                                                '--Cover-width': '40vw', // must be `vw` only
                                                '--Form-maxWidth': '700px',
                                                '--Transition-duration': '0.4s', // set to `none` to disable transition
                                                '--Sidebar-width': '224px',
                                                [theme.breakpoints.up('lg')]: {
                                                    '--Sidebar-width': '256px',
                                                },
                                            },
                                            '[data-feather], .feather': {
                                                color: 'var(--Icon-color)',
                                                margin: 'var(--Icon-margin)',
                                                fontSize:
                                                    'var(--Icon-fontSize, 20px)',
                                                width: '1em',
                                                height: '1em',
                                            },
                                        })}
                                    />
                                    <App />
                                </CssVarsProvider>
                            </MaterialCssVarsProvider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </StripeProvider>
            </DbProvider>
        </AuthProvider>
    </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
